import React from 'react'

import {
  GraphResulWrapper,
  ResultArrowWrapper,
  ResultArrow,
  ResultIndexTextWrapper,
  ResultIndexText,
  ResultIndexUnitsWrapper,
  ResultCelsiumUnits,
  ResultMmYearUnits,
  ResultGtUnits,
  ResultPercentUnits
} from './styled'

const GraphResult = ({
  graphResult,
  graphResultArrowDirection,
  graphResultMeasureUnit,
  graphResultIsLeftPos
}) => {
  const isUp = graphResultArrowDirection === 'up'

  const geMeasureUnit = () => {
    switch (graphResultMeasureUnit) {
      case 'celsium':
        return <ResultCelsiumUnits>C</ResultCelsiumUnits>
      case 'mm/year':
        return <ResultMmYearUnits>mm/year</ResultMmYearUnits>
      case 'gt':
        return <ResultGtUnits>gt</ResultGtUnits>
      case 'percent':
        return <ResultPercentUnits>% per decade</ResultPercentUnits>
      default:
        return null
    }
  }

  return (
    <GraphResulWrapper graphResultIsLeftPos={graphResultIsLeftPos}>
      <ResultArrowWrapper>
        <ResultArrow isUp={isUp} />
      </ResultArrowWrapper>

      <ResultIndexTextWrapper>
        <ResultIndexText>{graphResult}</ResultIndexText>
      </ResultIndexTextWrapper>

      <ResultIndexUnitsWrapper>{geMeasureUnit()}</ResultIndexUnitsWrapper>
    </GraphResulWrapper>
  )
}

export default GraphResult
