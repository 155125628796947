import styled, { keyframes, css } from 'styled-components'

import {
  PostComponentWrapper,
  PostEyeBackground,
  PostMainText,
  PostTitle,
  PostSecondaryText,
  PostDarkOverlay
} from '../../../../styled/postStyled'

import { siteBreakPoints } from '../../../../consts/styleConsts'

const { small } = siteBreakPoints
const width1460 = 1460
const width1300 = 1300
const height740 = 740

const FadeIn = keyframes`
  0% {opacity: 0; transform: translateY(30px)}
  50% {transform: translateY(0)}
  100% {opacity: 1; transform: translateY(0)}
`

export const ComponentWrapper = styled(PostComponentWrapper)``
export const DarkOverLay = styled(PostDarkOverlay)`
  @media only screen and (max-width: ${width1300}px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media only screen and (max-height: ${height740}px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`
export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  padding-left: 5.45rem;
  padding-right: 5.45rem;
  @media only screen and (max-width: ${width1460}px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media only screen and (max-width: ${width1300}px) {
    padding-top: 3rem;
  }
  @media only screen and (max-width: ${small}px) {
    padding-top: 8rem;
    padding-left: 1.87rem;
    padding-right: 1.87rem;
    margin-bottom: 3.75rem;
  }
`
export const Title = styled(PostTitle)`
  position: relative;
  ${({ isLoading }) =>
    !isLoading &&
    css`
      opacity: 0;
      animation: ${FadeIn} 1.5s ease forwards 0.2s;
    `}
  @media only screen and (max-width: ${width1300}px) {
    display: none;
  }
`
export const HiddenTitle = styled(PostTitle)`
  display: none;
  position: relative;
  ${({ isLoading }) =>
    !isLoading &&
    css`
      opacity: 0;
      animation: ${FadeIn} 1.5s ease forwards 0.2s;
    `}
  @media only screen and (max-width: ${width1300}px) {
    display: inline;
    margin-top: 3rem;
    margin-bottom: 2rem;
    width: 34.09rem;
  }
  @media only screen and (max-width: ${small}px) {
    width: 100%;
    margin-top: 5.06rem;
    margin-bottom: 2.5rem;
  }
`
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3.22rem;
  @media only screen and (max-width: ${width1300}px) {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 0;
  }
`
export const MainText = styled(PostMainText)`
  max-width: 34.09rem;
  position: relative;
  ${({ isLoading }) =>
    !isLoading &&
    css`
      opacity: 0;
      animation: ${FadeIn} 1.5s ease forwards 0.4s;
    `}
`
export const SecondaryText = styled(PostSecondaryText)`
  text-align: center;
  max-width: 27.77rem;
  position: relative;
  align-self: baseline;
  padding: 0 20px;
  ${({ isLoading }) =>
    !isLoading &&
    css`
      opacity: 0;
      animation: ${FadeIn} 1.5s ease forwards 0.6s;
    `}

  @media only screen and (max-width: ${width1300}px) {
    align-self: auto;
  }
`
export const EyeBackground = styled(PostEyeBackground)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: opacity 1s ease 1s, transform 1s ease 0.6s;
  ${({ isLoading }) =>
    !isLoading &&
    css`
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.2;
    `}
  @media only screen and (max-width: ${width1300}px) {
    transform: translate(-50%, -55%);
    ${({ isLoading }) =>
      !isLoading &&
      css`
        transform: translate(-50%, -55%) scale(1);
        opacity: 0.2;
      `}
  }
`
export const GraphWrapper = styled.div`
  width: 100%;
  height: 50vh;
  @media only screen and (max-width: ${width1300}px) {
    height: 16rem;
  }
  @media only screen and (max-width: ${small}px) {
    height: 9.75rem;
  }
`
export const FixedBottomWrapper = styled.div`
  bottom: 0;
  height: auto;
  position: fixed;
  width: 100%;
  @media only screen and (max-width: ${width1300}px) {
    position: relative;
  }
  @media only screen and (max-height: ${height740}px) {
    position: relative;
  }
`
