import React, { useEffect, useCallback } from 'react'
import { graphql, navigate } from 'gatsby'

import GrainBG from '../../LayoutsRelated/GrainBG/GrainBG'
import TwoColumnsLinesTemplate from '../PostTemplates/TwoColumnsLinesTemplate/TwoColumnsLinesTemplate'
import OneColumnLinesTemplate from '../PostTemplates/OneColumnLinesTemplate/OneColumnLinesTemplate'
import GraphTemplate from '../PostTemplates/GraphTemplate/GraphTemplate'
import MainTemplate from '../PostTemplates/MainTemplate/MainTemplate'
import SocialShareMeta from '../../Shared/SocialShareMeta/SocialShareMeta'
import { PostPageWrapper, CloseModalButton } from './styled'

import { useDispatch } from 'react-redux'
import { onSetEyeToViewed } from '../../../redux/eyesState/actions'

export const query = graphql`
  query PostsQueryMD($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        secondaryTitle
        tertiaryText
        canvasPoint
        secondaryText
        template
        graphResult
        graphResultArrowDirection
        graphResultMeasureUnit
        graphResultIsLeftPos
        graphData {
          childrenGraphDataJson {
            x
            y
          }
        }
        backgroundImage {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              aspectRatio
              originalImg
              originalName
              presentationHeight
              presentationWidth
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
    }
  }
`

const PostPage = props => {
  const dispatch = useDispatch()

  const post = props.data.markdownRemark
  const postTemplate = post.frontmatter.template
  const eyeName = post.frontmatter.canvasPoint

  const getTemplate = () => {
    switch (postTemplate) {
      case 'one column lines template':
        return <OneColumnLinesTemplate post={post} />
      case 'two columns lines template':
        return <TwoColumnsLinesTemplate post={post} />
      case 'graph template':
        return <GraphTemplate post={post} />
      case 'main template':
        return <MainTemplate post={post} />
      default:
        return `There was a problem with the MD post file (no template found / provided)`
    }
  }

  const onClosePage = useCallback(() => {
    navigate('/')
    dispatch(onSetEyeToViewed(eyeName))
  }, [dispatch, eyeName])

  useEffect(() => {
    window.addEventListener('popstate', onClosePage)

    const onKeyPress = ({ keyCode }) => (keyCode === 27 ? onClosePage() : null)
    document.addEventListener('keydown', onKeyPress, false)

    return () => {
      document.removeEventListener('keydown', onKeyPress, false)
      window.removeEventListener('popstate', onClosePage)
    }
  }, [onClosePage])

  return (
    <PostPageWrapper>
      <SocialShareMeta />
      <CloseModalButton onClick={onClosePage} />
      <GrainBG />
      {getTemplate()}
    </PostPageWrapper>
  )
}

export default PostPage
