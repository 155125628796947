import styled, { css } from 'styled-components'
import { siteBreakPoints, colors } from '../../../../../../consts/styleConsts'

const { small } = siteBreakPoints
const { orange } = colors.secondary

export const GraphResulWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 2rem;
  display: flex;
  ${({graphResultIsLeftPos}) => graphResultIsLeftPos && css`
    right: auto;
    left: 0;
  `}
  @media only screen and (max-width: ${small}px) {
    margin: 0.31rem 0.87rem;
  }
`
export const ResultArrowWrapper = styled.div``
export const ResultArrow = styled.div`
  position: relative;
  width: 0.18rem;
  height: 3.63rem;
  background: ${orange};
  transform: translateY(50%);
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 0.9rem solid transparent;
    border-bottom: 0.9rem solid #ff820c;
    border-right: 0.9rem solid transparent;
    border-left: 0.9rem solid transparent;
    top: 0;
    left: 50%;
    transform: translate(-50%, -53%);
  }
  ${({ isUp }) =>
    !isUp &&
    css`
      transform: translateY(50%) rotate(180deg);
    `}
  @media only screen and (max-width: ${small}px) {
    width: 0.12rem;
    height: 1.87rem;
    &:after {
      border-width: 0.62rem;
    }
    ${({ isUp }) =>
      !isUp &&
      css`
        transform: translateY(50%) rotate(180deg);
      `}
  }
`
export const ResultIndexTextWrapper = styled.div`
  margin-left: 2.72rem;
  @media only screen and (max-width: ${small}px) {
    margin-left: 1.25rem;
  }
`
export const ResultIndexText = styled.span`
  font-family: Plex-Bold;
  font-size: 4.54rem;
  line-height: 5.86rem;
  color: #ffffff;
  @media only screen and (max-width: ${small}px) {
    font-size: 2.37rem;
    line-height: 3.06rem;
  }
`
export const ResultIndexUnitsWrapper = styled.div``
export const ResultCelsiumUnits = styled(ResultIndexText)`
  position: relative;
  margin-left: 0.9rem;
  &:before {
    content: 'o';
    position: absolute;
    top: 0;
    transform: translate(-50%, 50%);
    font-size: 1.36rem;
    line-height: 1.77rem;
    left: -0.31rem;
  }
  @media only screen and (max-width: ${small}px) {
    font-size: 2.37rem;
    line-height: 3.06rem;
    &:before {
      font-size: 0.68rem;
      line-height: 1.18rem;
    }
  }
`
export const ResultMmYearUnits = styled.div`
  color: #ffffff;
  font-family: Plex;
  font-size: 1.54rem;
  line-height: 2rem;
  margin-left: 0.45rem;
  top: 2.72rem;
  font-weight: bold;
  letter-spacing: 0;
  position: relative;
  @media only screen and (max-width: ${small}px) {
    font-size: 1.25rem;
    top: 0.8rem;
  }
`
export const ResultGtUnits = styled.div`
  color: #ffffff;
  font-family: Plex;
  font-size: 1.54remm;
  line-height: 2rem;
  margin-left: 0.45rem;
  top: 2.72rem;
  font-weight: bold;
  letter-spacing: 0;
  position: relative;
  @media only screen and (max-width: ${small}px) {
    font-size: 1.25rem;
    top: 0.8rem;
  }
`
export const ResultPercentUnits = styled.div`
  color: #ffffff;
  font-family: Plex;
  font-size: 1.54remm;
  line-height: 2rem;
  margin-left: 0.45rem;
  top: 2.72rem;
  font-weight: bold;
  letter-spacing: 0;
  position: relative;
  @media only screen and (max-width: ${small}px) {
    font-size: 1.25rem;
    top: 0.8rem;
  }
`
