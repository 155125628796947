import React from 'react'
import { Line } from 'react-chartjs-2'

import useGraphData from '../../../../../../hooks/useGraphData'

const Graph = ({ graphData }) => {
  const { getState, getOptions } = useGraphData(graphData)

  return <Line data={getState()} options={getOptions()} />
}

export default Graph
