import React, { useState } from 'react'

import {
  ComponentWrapper,
  DarkOverlay,
  Title,
  MainConentWrapper,
  MainText,
  EyeBackground,
  SecondaryTextWrapper,
  SecondaryText
} from './styled'
import LoadingScreen from '../../../Shared/LoadingScreen/LoadingScreen'

import eyeOpenedNoBG from '../../../../images/eye-opened-main-page.svg'

const TwoColumnsLinesTemplate = ({ post }) => {
  const [isLoading, setIsLoading] = useState(true)

  const backgroundImage = post.frontmatter.backgroundImage.childImageSharp.fluid
  const { title, secondaryText } = post.frontmatter
  return (
    <>
      {isLoading ? <LoadingScreen isLoading={isLoading} /> : null}
      <ComponentWrapper
        fluid={backgroundImage}
        onLoad={() => setIsLoading(false)}
      >
        <DarkOverlay>
          <MainConentWrapper>
            <Title isLoading={isLoading}>
              {title}
              <EyeBackground src={eyeOpenedNoBG} isLoading={isLoading} />
            </Title>
            <MainText
              dangerouslySetInnerHTML={{ __html: post.html }}
              isLoading={isLoading}
            />
          </MainConentWrapper>
          <SecondaryTextWrapper>
            <SecondaryText isLoading={isLoading}>{secondaryText}</SecondaryText>
          </SecondaryTextWrapper>
        </DarkOverlay>
      </ComponentWrapper>
    </>
  )
}

export default TwoColumnsLinesTemplate
