import React, { useState } from 'react'

import {
  ComponentWrapper,
  DarkOverlay,
  Title,
  TitleHidden,
  Container,
  MainTextWrapepr,
  MainText,
  SecondaryTextWrapper,
  SecondaryText,
  ThirdTextWrapper,
  ThirdText,
  EyeBackground,
  ContentWrapper
} from './styled'
import LoadingScreen from '../../../Shared/LoadingScreen/LoadingScreen'

import eyeOpenedNoBG from '../../../../images/eye-opened-main-page.svg'

const OneColumnLinesTemplate = ({ post }) => {
  const [isLoading, setIsLoading] = useState(true)

  const backgroundImage = post.frontmatter.backgroundImage.childImageSharp.fluid
  const { title, secondaryText, tertiaryText } = post.frontmatter
  return (
    <>
      {isLoading ? <LoadingScreen isLoading={isLoading} /> : null}
      <ComponentWrapper
        fluid={backgroundImage}
        onLoad={() => setIsLoading(false)}
      >
        <DarkOverlay>
          <ContentWrapper>
            <Title isLoading={isLoading}>
              {title}
              <EyeBackground src={eyeOpenedNoBG} isLoading={isLoading} />
            </Title>
            <Container>
              <MainTextWrapepr>
                <TitleHidden isLoading={isLoading}>
                  {title}
                  <EyeBackground src={eyeOpenedNoBG} isLoading={isLoading} />
                </TitleHidden>
                <MainText
                  dangerouslySetInnerHTML={{ __html: post.html }}
                  isLoading={isLoading}
                />
              </MainTextWrapepr>

              <SecondaryTextWrapper isLoading={isLoading}>
                <SecondaryText>{secondaryText}</SecondaryText>
              </SecondaryTextWrapper>
            </Container>
          </ContentWrapper>

          <ThirdTextWrapper isLoading={isLoading}>
            <ThirdText>{tertiaryText}</ThirdText>
          </ThirdTextWrapper>
        </DarkOverlay>
      </ComponentWrapper>
    </>
  )
}

export default OneColumnLinesTemplate
