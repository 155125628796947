import styled from 'styled-components'

import { removeBackgroundOnClick } from '../../../styled/extends'

import { colors } from '../../../consts/styleConsts'
const { grey3 } = colors.grey

export const PostPageWrapper = styled.div``

export const CloseModalButton = styled.div`
  ${removeBackgroundOnClick};
  position: fixed;
  top: 20px;
  right: 0;
  margin-right: 25px;
  width: 40px;
  height: 24px;
  cursor: pointer;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    width: 80%;
    background: ${grey3};
    height: 2px;
    transform: rotate(-45deg);
    top: 50%;
    left: 0;
  }
  &:after {
    content: '';
    position: absolute;
    width: 80%;
    background: ${grey3};
    height: 2px;
    transform: rotate(45deg);
    bottom: 41%;
  }
`
