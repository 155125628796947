import React, { useState } from 'react'

import {
  ComponentWrapper,
  MainText,
  Title,
  SecondaryText,
  EyeBackground,
  DarkOverLay,
  GraphWrapper,
  ContentWrapper,
  Container,
  FixedBottomWrapper,
  HiddenTitle
} from './styled'
import Graph from './components/Graph/Graph'
import GraphResult from './components/GraphResult/GraphResult'
import LoadingScreen from '../../../../components/Shared/LoadingScreen/LoadingScreen'

import eyeOpenedNoBG from '../../../../images/eye-opened-main-page.svg'

const GraphTemplate = ({ post }) => {
  const [isLoading, setIsLoading] = useState(true)
  const {
    graphResult,
    graphResultArrowDirection,
    graphResultMeasureUnit,
    graphResultIsLeftPos
  } = post.frontmatter
  const backgroundImage = post.frontmatter.backgroundImage.childImageSharp.fluid
  const graphData = post.frontmatter.graphData.childrenGraphDataJson
  return (
    <>
      {isLoading ? <LoadingScreen isLoading={isLoading} /> : null}
      <ComponentWrapper
        fluid={backgroundImage}
        onLoad={() => setIsLoading(false)}
      >
        <DarkOverLay>
          <ContentWrapper>
            <Title isLoading={isLoading}>{post.frontmatter.title}</Title>
            <Container>
              <MainText
                dangerouslySetInnerHTML={{ __html: post.html }}
                isLoading={isLoading}
              />
              <HiddenTitle isLoading={isLoading}>
                {post.frontmatter.title}
              </HiddenTitle>
              <SecondaryText isLoading={isLoading}>
                {post.frontmatter.secondaryText}
                <EyeBackground src={eyeOpenedNoBG} isLoading={isLoading} />
              </SecondaryText>
            </Container>
          </ContentWrapper>

          <FixedBottomWrapper>
            <GraphWrapper>
              <Graph graphData={graphData} />
              <GraphResult
                graphResult={graphResult}
                graphResultArrowDirection={graphResultArrowDirection}
                graphResultMeasureUnit={graphResultMeasureUnit}
                graphResultIsLeftPos={graphResultIsLeftPos}
              />
            </GraphWrapper>
          </FixedBottomWrapper>
        </DarkOverLay>
      </ComponentWrapper>
    </>
  )
}

export default GraphTemplate
