const useGraphData = jsonData => {
  const labels = jsonData.map(v => v.x)
  // Make sure all the values are positive +10000
  const data = jsonData.map(v => v.y + 10000)

  const getState = () => {
    const state = {
      labels: labels,
      datasets: [
        {
          fill: true,
          lineTension: 0.5,
          backgroundColor: 'rgba(106, 182, 243, 0.4588235294117647)',
          borderColor: '#6AB6F3',
          borderWidth: 3,
          data: data,
          pointRadius: 0
        }
      ]
    }
    return state
  }

  const getOptions = () => {
    const options = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            ticks: {
              display: false
            },
            gridLines: {
              display: false,
              drawBorder: false,
              drawTicks: false
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              display: false
            },
            gridLines: {
              display: false,
              drawBorder: false,
              drawTicks: false
            }
          }
        ]
      }
    }
    return options
  }

  return { getState, getOptions }
}

export default useGraphData
