import styled, { css, keyframes } from 'styled-components'
import {
  PostComponentWrapper,
  PostDarkOverlay,
  PostMainText,
  PostSecondaryText,
  PostTitle,
  PostEyeBackground
} from '../../../../styled/postStyled'

import { siteBreakPoints, colors } from '../../../../consts/styleConsts'

const { small, large } = siteBreakPoints
const { lightBlue } = colors.secondary
const width1500 = 1500
const width1400 = 1400

const FadeIn = keyframes`
  0% {opacity: 0; transform: translateY(30px)}
  50% {transform: translateY(0)}
  100% {opacity: 1; transform: translateY(0)}
`

export const ComponentWrapper = styled(PostComponentWrapper)``
export const DarkOverlay = styled(PostDarkOverlay)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const ContentWrapper = styled.div`
  padding-top: 10rem;
  padding-left: 11rem;

  padding-bottom: 2.27rem;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: ${width1500}px) {
    padding-top: 8rem;
  }
  @media only screen and (max-width: ${width1400}px) {
    flex-direction: column-reverse;
    padding-top: 3rem;
  }
  @media only screen and (max-width: ${large}px) {
    padding-left: 5rem;
  }
  @media only screen and (max-width: ${small}px) {
    padding-top: 4.37rem;
    padding-left: 3.12rem;
    padding-right: 0.62rem;
  }
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2.45rem;
  @media only screen and (max-width: ${width1400}px) {
    flex-direction: column-reverse;
    margin-top: 0;
  }
`
export const MainTextWrapepr = styled.div`
  padding-top: 1.13rem;
  max-width: 33.18rem;
  margin-right: 80px;
  @media only screen and (max-width: ${width1400}px) {
    max-width: 80%;
    margin-top: 2rem;
    margin-right: 0;
  }
  @media only screen and (max-width: ${small}px) {
    max-width: 100%;
  }
`
export const MainText = styled(PostMainText)`
  position: relative;
  ${({ isLoading }) =>
    !isLoading &&
    css`
      opacity: 0;
      animation: ${FadeIn} 1.5s ease forwards 0.4s;
    `}
  @media only screen and (max-width: ${width1400}px) {
    margin-top: 1rem;
  }
`
export const SecondaryTextWrapper = styled.div`
  max-width: 29.09rem;
  padding-top: 1.81rem;
  padding-right: 0.9rem;
  position: relative;
  ${({ isLoading }) =>
    !isLoading &&
    css`
      opacity: 0;
      animation: ${FadeIn} 1.5s ease forwards 0.6s;
    `}
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 2px;
    background: ${lightBlue};
  }
  @media only screen and (max-width: ${width1400}px) {
    align-self: flex-end;
  }
  @media only screen and (max-width: ${small}px) {
    padding-right: 0px;
    max-width: 100%;
    left: 0;
    transform: translateX(0);
  }
`
export const SecondaryText = styled(PostSecondaryText)``
export const ThirdTextWrapper = styled.div`
  max-width: 29.09rem;
  position: relative;
  padding-bottom: 1.54rem;
  margin-left: 11rem;
  margin-bottom: 4.86rem;
  ${({ isLoading }) =>
    !isLoading &&
    css`
      opacity: 0;
      animation: ${FadeIn} 1.5s ease forwards 0.8s;
    `}
  &:before {
    content: '';
    position: absolute;
    right: 50%;
    bottom: 0;
    width: 100vw;
    height: 2px;
    background: ${lightBlue};
  }
  @media only screen and (max-width: ${large}px) {
    margin-left: 5rem;
  }
  @media only screen and (max-width: ${small}px) {
    left: -2.5rem;
    max-width: 100%;
    margin-left: 3.18rem;
  }
`
export const ThirdText = styled(SecondaryText)`
  position: relative;
  ${({ isLoading }) =>
    !isLoading &&
    css`
      opacity: 0;
      animation: ${FadeIn} 1.5s ease forwards 0.8s;
    `}
`
export const Title = styled(PostTitle)`
  position: relative;
  ${({ isLoading }) =>
    !isLoading &&
    css`
      opacity: 0;
      animation: ${FadeIn} 1.5s ease forwards 0.2s;
    `}
  @media only screen and (max-width: ${width1400}px) {
    display: none;
  }
`
export const TitleHidden = styled(Title)`
  display: none;
  position: relative;
  ${({ isLoading }) =>
    !isLoading &&
    css`
      opacity: 0;
      animation: ${FadeIn} 1.5s ease forwards 0.2s;
    `}
  @media only screen and (max-width: ${width1400}px) {
    display: block;
  }
`
export const EyeBackground = styled(PostEyeBackground)`
  left: 0;
  top: 0;
  transform: translate(-45%, -55%) scale(0);
  transition: opacity 1s ease 1s, transform 1s ease 0.6s;
  opacity: 0;
  ${({ isLoading }) =>
    !isLoading &&
    css`
      opacity: 0.2;
      transform: translate(-45%, -55%) scale(1);
    `}
`
